import { createSelector, createFeatureSelector } from '@ngrx/store';

import { UserState } from './user.state';
import { User } from '@core/models'
export const selectUserState = createFeatureSelector<UserState>('user');


export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state!
);

export const selectUserRoles = createSelector(
  selectUserState,
  (state: UserState) => state.roles!
);

